<template>
  <div :class="$style.wrap">
    <img src="./jpyx.png" alt="">
    <ul>
      <li
        v-for="(game,index) in gameList"
        :key="index"
        :class="{[$style.active]:index===activeIndex}"
        @click="handleClick(index)"
      >
        <a href="javascript:void(0)" class="a-label-reset">
          <span>{{index+1}}</span>
          {{game.name}}
        </a>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  props: {
    gameList: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      activeIndex: 0
    };
  },
  methods: {
    handleClick(index) {
      this.activeIndex = index;
      this.$router.push(`/detail/${this.gameList[index].id}`);
    },
  }
};
</script>

<style module>
.wrap {
  flex: 1;
  max-width: 350px;
  margin-right: 81px;
}

.wrap > img {
  margin-bottom: 20px;
}

.wrap ul {
  padding-left: 0;
}

.wrap li {
  font-size: 14px;
  font-family: "微软雅黑";
  list-style-type: none;
  padding: 15px 0;
  cursor: pointer;
  border-bottom: 1px solid #dcdcdc;
}

.wrap li:nth-child(1) {
  border-top: 1px solid #dcdcdc;
}

.wrap a {
  vertical-align: middle;
}


.wrap li span {
  width: 22px;
  height: 22px;
  margin-right: 13px;
  display: inline-block;
  text-align: center;
  vertical-align: middle;
  border: 1px solid #ff7514;
  border-radius: 50%;
  color: #ff7514;
  font-size: 12px;
}

.wrap ul:hover li.active span {
  color: #ff7514;
  background-color: transparent;
}

.wrap li.active span {
  color: white;
  background-color: #ff7514;
}

.wrap ul:hover li:hover span {
  color: white;
  background-color: #ff7514;
}
</style>
