<template>
  <div :class="$style.wrap">
    <img :src="qrcode" alt="" :class="$style.qrcode">
    <img :src="game.icon" alt="" :class="$style.icon">
    <p>扫码下载</p>
  </div>
</template>

<script>
import axios from "axios";
import QRCode from "qrcode";

export default {
  props: {
    game: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      qrcode: null
    };
  },
  watch: {
    game() {
      this.generateQRCode();
    }
  },
  methods: {
    generateQRCode() {
      let sourceUrl = `${axios.defaults.baseURL}/game/qcode?id=${this.game.id}`;
      QRCode.toDataURL(sourceUrl, {
        errorCorrectionLevel: "H",
        margin: 0
      })
        .then(url => {
          this.qrcode = url;
        })
        .catch(err => {
          console.error("qrcode generate err");
        });
    }
  }
};
</script>

<style module>
.wrap {
  position: relative;
  text-align: center;
}

.wrap p {
  margin-top: 21px;
}
.qrcode {
  width: 95px;
  height: 95px;
}
.icon {
  width: 26px;
  height: 26px;
  position: absolute;
  top: 34.5px;
  left: 50%;
  margin-left: -13px;
}
</style>
