<template>
  <swiper :options="swiperOption" :class="$style.wrap" ref="gameDetailSlider">
    <!-- slides -->
    <swiper-slide v-for="item in gameScreenshots" :key="item">
      <div :style="`background-image:url(${item})`" :class="$style.itemBg"></div>
    </swiper-slide>
    <!-- Optional controls -->
    <div class="swiper-pagination" slot="pagination"></div>
  </swiper>
</template>

<script>
import { swiper, swiperSlide } from "vue-awesome-swiper";

export default {
  props: {
    game: {
      type: Object,
      required: true
    }
  },
  components: {
    swiper,
    swiperSlide
  },
  data() {
    return {
      activeIndex: 0,
      swiperOption: {
        loop: true,
        autoplay: {
          loadPrevNext: true,
          loadOnTransitionStart: true
        },
        pagination: {
          el: ".swiper-pagination",
          clickable: true
        },
        observer: true,
        observeParents: true
      }
    };
  },

  computed: {
    gameScreenshots() {
      if (this.game.gameScreenshot) {
        return this.game.gameScreenshot.split(",");
      }
      return [];
    }
  },
  watch: {
    game() {
      this.$refs.gameDetailSlider.swiper.slideTo(0);
    }
  }
};
</script>

<style module>
.wrap {
  width: 306px;
  height: 543px;
  margin: 52px;
  margin-left: 0;
  border: 3px solid #dcdcdc;
  box-sizing: content-box;
}

.itemBg {
  display: block;
  width: 100%;
  height: 100%;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}

.wrap :global(.swiper-pagination-bullet) {
  width: 10px;
  height: 10px;
  border: 1px solid #fb700f;
  border-radius: 50%;
  margin: 3px;
  opacity: 1;
  background: initial;
}

.wrap :global(.swiper-pagination-bullet-active) {
  background-color: #fb700f;
}
</style>
