<template>
  <div>
    <mly-header></mly-header>
    <div :class="$style.wrap">
      <p :class="$style.return">
        <router-link to="/product">&lt;返回</router-link>
      </p>
      <div :class="$style.content">
        <game-list :game-list="gameList"></game-list>
        <game-detail :game="game"></game-detail>
      </div>
    </div>
    <mly-footer></mly-footer>
  </div>
</template>

<script>
import axios from "axios";

import gameList from "./game-list";
import gameDetail from "./game-detail";

export default {
  props: {
    id: {
      type: String,
      required: true
    }
  },
  components: {
    gameList,
    gameDetail
  },
  created() {
    this.queryGameById();
    this.queryGameList();
  },
  data() {
    return {
      gameList: [],
      game: {}
    };
  },
  methods: {
    queryGameList() {
      axios
        .get("/game/listGame?operateSys=1&pageNum=1&pageRow=9")
        .then(response => {
          if (response.returnCode == this.CONST.QuerySuccess) {
            this.gameList = response.returnData.list;
          }
        });
    },
    queryGameById() {
      axios
        .get("/game/getGameById", {
          params: {
            id: this.id
          }
        })
        .then(response => {
            this.game = response.returnData;
        });
    }
  },
  watch: {
    $route(to, from) {
      this.queryGameById();
    }
  }
};
</script>

<style module>
.wrap {
  width: 1200px;
  margin: 0 auto;
  margin-top: 40px;
  padding-left: 36px;
}

.return {
  margin: 32px 0;
}

.return a {
  font-size: 18px;
  color: #1696df;
}

.content {
  display: flex;
  justify-content: space-between;
  min-height: 650px;
}
</style>
