<template>
  <div :class="$style.wrap">
    <img src="./yxxq.png" alt="">
    <div :class="$style.line"></div>
    <div :class="$style.main">
      <slider :game="game"></slider>
      <div :class="$style.content">
        <dl class="clearfix">
          <dt>
            <img :src="game.icon" alt="">
          </dt>
          <dd>
            <p>{{game.name}}</p>
            <p>{{game.gameTypeName}}</p>
          </dd>
        </dl>
        <p :class="$style.desc">{{game.content}}</p>
        <qrcode :game="game"></qrcode>
      </div>
    </div>
  </div>
</template>

<script>
import { swiper, swiperSlide } from "vue-awesome-swiper";
import slider from "./slider";

import qrcode from "./qrcode";

export default {
  components: {
    swiper,
    swiperSlide,
    slider,
    qrcode
  },
  props: {
    game: {
      type: Object,
      required: true
    }
  }
};
</script>

<style module>
.wrap {
  flex: 3;
  position: relative;
}
.wrap > img {
  display: block;
}

.line{
  width: 100%;
  height: 1px;
  background-color: #ddccdd;
  position: absolute;
  top:41px;
}

.main {
  display: flex;
}

.content {
  flex: 1;
  margin-top: 52px;
  max-width: 470px;
}

.content dl {
  margin-bottom: 0;
  padding-bottom: 25px;
  border-bottom:1px solid #dcdcdc;
}

.content dt,
.content dd {
  float: left;
}

.content dd {
  margin-top: 2em;
  margin-left: 1em;
  display: flex;
  flex-direction: column;
}

.content dd p:nth-child(1) {
  font-size: 16px;
  color: black;
}

.content dt img {
  width: 106px;
  height: 106px;
}

.content p {
  font-size: 13px;
  color: #666666;
}

.desc {
  line-height: 2.5;
  margin: 35px 0 60px 0;
}
</style>
